import React from 'react'

import { useField, ErrorMessage } from 'formik'
import { Input, Label } from 'reactstrap'

function FormikCheckbox({ label, customCall, ...props }) {
  const [field, meta, { setValue, setTouched }] = useField(props)

  const onChange = (e) => {
    if (customCall) {
      customCall(e.target.checked)
    } else setValue(e.target.checked)
  }
  return (
    <>
      <div className="form-check form-check-inline">
        <Input
          name={field.name}
          id={field.name}
          type="checkbox"
          checked={field.value}
          locale="pt"
          onChange={onChange}
          disabled={props.disabled}
          onBlur={setTouched}
          className={
            meta.error && meta.touched
              ? ' is-invalid form-check-input'
              : 'form-check-input'
          }
        />
        <Label for={field.name} className="mb-0">
          {label}
        </Label>
      </div>
      {meta.touched && meta.error && (
        <div className="invalid-feedback">
          {meta.error?.label || meta.error}
        </div>
      )}
    </>
  )
}
export default FormikCheckbox
