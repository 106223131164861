import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { Nav, Collapse } from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'

import profile from '../../../assets/images/users/5.png'

const mapStateToProps = (state) => ({
  ...state,
})

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.expandLogo = this.expandLogo.bind(this)
    this.toggle = this.toggle.bind(this)
    this.activeRoute.bind(this)
    this.state = {
      collapse: false,
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  expandLogo() {
    document.getElementById('logobg').classList.toggle('expand-logo')
  }
  toggle() {
    this.setState((state) => ({ collapse: !state.collapse }))
  }

  showMobilemenu = () => {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar')
  }
  /*--------------------------------------------------------------------------------*/
  /*Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? 'selected'
      : ''
  }
  render() {
    return (
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg={this.props.settings.activeSidebarBg}
        onMouseEnter={this.expandLogo}
        onMouseLeave={this.expandLogo}
      >
        <div className="scroll-sidebar">
          <PerfectScrollbar className="sidebar-nav">
            {/*--------------------------------------------------------------------------------*/}
            {/* Sidebar Menus will go here                                                */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav id="sidebarnav">
              {this.props?.users?.gestao?.length > 0 && (
                <>
                  <li className="sidebar-item user-profile">
                    <NavLink
                      to={'/dashboard'}
                      className="sidebar-link"
                      activeClassName="active"
                    >
                      <i className="mdi mdi-view-dashboard"></i>
                      <span className="hide-menu">A minha área</span>
                    </NavLink>
                  </li>
                </>
              )}
              {this.props.routes.map((prop, key) => {
                if (
                  prop.keyModule &&
                  this.props.appModules.modules[prop.keyModule] === false
                )
                  return null
                if (prop.redirect) {
                  return null
                } else if (prop.notVisible) {
                  return null
                } else if (prop.navlabel) {
                  return (
                    <li className="nav-small-cap" key={key}>
                      <i className={prop.icon}></i>
                      <span className="hide-menu">{prop.name}</span>
                    </li>
                  )
                } else if (prop.collapse) {
                  let firstdd = {}
                  firstdd[prop['state']] = !this.state[prop.state]
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Menus will be here                                                        */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      className={this.activeRoute(prop.path) + ' sidebar-item'}
                      key={key}
                    >
                      <span
                        data-toggle="collapse"
                        className="sidebar-link has-arrow"
                        aria-expanded={this.state[prop.state]}
                        onClick={() => this.setState(firstdd)}
                      >
                        <i className={prop.icon} />
                        <span className="hide-menu">
                          {prop.name}
                          <span className={prop.badges}>{prop.badgeno}</span>
                        </span>
                      </span>
                      {/*--------------------------------------------------------------------------------*/}
                      {/* Sub-Menus wiil be here                                                    */}
                      {/*--------------------------------------------------------------------------------*/}
                      <Collapse isOpen={this.state[prop.state]}>
                        <ul className="first-level">
                          {prop.child.map((prop, key) => {
                            if (prop.redirect) return null
                            if (prop.collapse) {
                              let seconddd = {}
                              seconddd[prop['state']] = !this.state[prop.state]
                              return (
                                <li
                                  className={
                                    this.activeRoute(prop.path) +
                                    ' sidebar-item'
                                  }
                                  key={key}
                                >
                                  <span
                                    data-toggle="collapse"
                                    className="sidebar-link has-arrow"
                                    aria-expanded={this.state[prop.state]}
                                    onClick={() => this.setState(seconddd)}
                                  >
                                    <i className={prop.icon} />
                                    <span className="hide-menu">
                                      {prop.name}
                                    </span>
                                  </span>
                                  {/*--------------------------------------------------------------------------------*/}
                                  {/* Sub-Menus wiil be here                                                    */}
                                  {/*--------------------------------------------------------------------------------*/}
                                  <Collapse isOpen={this.state[prop.state]}>
                                    <ul className="second-level">
                                      {prop.subchild.map((prop, key) => {
                                        if (prop.redirect) return null
                                        return (
                                          <li
                                            className={
                                              this.activeRoute(prop.path) +
                                              ' sidebar-item'
                                            }
                                            key={key}
                                          >
                                            <NavLink
                                              to={prop.path}
                                              activeClassName="active"
                                              className="sidebar-link"
                                            >
                                              <i className={prop.icon} />
                                              <span className="hide-menu">
                                                {prop.name}
                                              </span>
                                            </NavLink>
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </Collapse>
                                </li>
                              )
                            }
                            return (
                              /*--------------------------------------------------------------------------------*/
                              /* Adding Sidebar Item                                                            */
                              /*--------------------------------------------------------------------------------*/
                              <li
                                onClick={this.showMobilemenu}
                                className={
                                  this.activeRoute(prop.path) +
                                  (prop.pro ? ' active active-pro' : '') +
                                  ' sidebar-item'
                                }
                                key={key}
                              >
                                <NavLink
                                  to={prop.path}
                                  className="sidebar-link"
                                  activeClassName="active"
                                >
                                  <i className={prop.icon} />
                                  <span className="hide-menu">{prop.name}</span>
                                </NavLink>
                              </li>
                            )
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  )
                } else {
                  return (
                    /*--------------------------------------------------------------------------------*/
                    /* Adding Sidebar Item                                                            */
                    /*--------------------------------------------------------------------------------*/
                    <li
                      onClick={this.showMobilemenu}
                      className={
                        this.activeRoute(prop.path) +
                        (prop.pro ? ' active active-pro' : '') +
                        ' sidebar-item'
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop.path}
                        className="sidebar-link"
                        activeClassName="active"
                      >
                        <i className={prop.icon} />
                        <span className="hide-menu">{prop.name}</span>
                      </NavLink>
                    </li>
                  )
                }
              })}
              <li className={' sidebar-item'}>
                <a
                  href="https://geoatributo.zendesk.com/hc/pt/requests/new"
                  target="_blank"
                  rel="noopener"
                  className="sidebar-link"
                  activeClassName="active"
                >
                  <i className="mdi mdi-help-circle" />
                  <span className="hide-menu">Suporte</span>
                </a>
              </li>
            </Nav>
          </PerfectScrollbar>
        </div>
      </aside>
    )
  }
}
export default connect(mapStateToProps)(Sidebar)
