const authorizationHeader = () =>
  new Headers({
    Authorization: `Bearer ${localStorage.getItem('CIRA_TOKEN')}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    credentials: 'include',
  })

export default authorizationHeader
