import { lazy } from 'react'
import Administration from '../views/administration/index.js'
const FullLayout = lazy(() => import('../layouts/FullLayout.js'))
const Authentication = lazy(() => import('../views/authentication/Login'))
const Register = lazy(() => import('../views/authentication/Registar'))
const ResetPassword = lazy(() =>
  import('../views/authentication/ResetPassword')
)
const ConfirmPassword = lazy(() =>
  import('../views/authentication/ConfirmPassword')
)

const RecoverPassword = lazy(() =>
  import('../views/authentication/RecoverPassword')
)

var indexRoutes = [
  {
    path: '/authentication/login',
    name: 'Autenticação',
    component: Authentication,
  },
  {
    path: '/authentication/register',
    name: 'Registar',
    component: Register,
  },
  {
    path: '/reset_password/:token',
    name: 'Recuperar Palavra-passe',
    component: ResetPassword,
  },
  {
    path: '/confirmation/:token',
    name: 'Ativar Conta',
    component: ConfirmPassword,
  },

  {
    path: '/authentication/recover',
    name: 'Autenticação',
    component: RecoverPassword,
  },
  { path: '/', name: 'Início', component: FullLayout },
]

export default indexRoutes
