import React, { useEffect } from 'react'
import { Alert } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { messageAlert } from '../../redux/users/Action'

/*
  Success
  Danger
  Warning
  Info
  Light
  Dark
*/

function MessageAlert() {
  const dispatch = useDispatch()
  const { type, message } = useSelector((state) => ({
    ...state.users.messageAlert,
  }))

  useEffect(() => {
    let timeout = setTimeout(() => dispatch(messageAlert(type, null)), 5000)

    return () => clearTimeout(timeout)
  }, [type, message])
  return (
    <div className="cira-alert" style={{ maxWidth: '800px' }}>
      <Alert
        color={type}
        isOpen={message}
        toggle={() => dispatch(messageAlert(type, null))}
      >
        {message}
      </Alert>
    </div>
  )
}

export default MessageAlert
