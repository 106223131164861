import React, { useState } from 'react'
import ReactSelect from 'react-select'
import { History } from '../../../jwt/_helpers'

const options = [
  { href: '/noticias', label: 'Últimas Notícias', value: 'Notícias' },
  { href: '/avisos', label: 'Avisos/Alertas', value: 'Avisos/Alertas' },
  { href: '/ocorrencias', label: 'Mapa de Ocorrências', value: 'Notícias' },
  {
    href: '/meteorologia/previsao',
    label: 'Previsão de Meteorologia',
    value: 'Avisos/Alertas',
  },
  {
    href: '/meteorologia/estacoes',
    label: 'Estações Meteorológicas',
    value: 'Avisos/Alertas',
  },
  {
    href: '/procedimentos/informacao',
    label: 'Procedimentos de Autoproteção',
    value: 'Avisos/Alertas',
  },
]
const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    height: '100%',
    border: '0',
  }),
  control: (provided, state) => ({
    ...provided,
    width: '100%',
    height: '100%',
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      border: state.isFocused ? 0 : 0,
    },
  }),
}
function SelectCombo() {
  const [isOpen, setMenu] = useState(false)
  return (
    <ReactSelect
      options={options}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
      }}
      value={null}
      isMulti={true}
      onChange={(value) => {
        console.log(value)
        if (value && value.length > 0) History.push(value?.[0].href)
      }}
      noOptionsMessage={() => null}
      openMenuOnClick={false}
      openMenuOnFocus={false}
      placeholder="Pesquisar"
      styles={customStyles}
      blurInputOnSelect={false}
    />
  )
}

export default SelectCombo
