import { lazy } from 'react'
const NoticiasItem = lazy(() => import('../views/noticias/NoticiasItem'))

const Homepage = lazy(() => import('../views/homepage/Homepage'))

const Avisos = lazy(() => import('../views/avisos/Avisos'))

const Meteo = lazy(() => import('../views/meteo/Meteo'))

const Estacao = lazy(() => import('../views/meteo/Estacao'))

const Mapa = lazy(() => import('../views/mapa/Mapa'))

const Contactos = lazy(() => import('../views/contactos/Contactos'))

const Procedimentos = lazy(() => import('../views/procedimentos/Procedimentos'))
const Orientacoes = lazy(() => import('../views/procedimentos/Orientacoes'))

const AutoProtecao = lazy(() => import('../views/procedimentos/Autoprotecao'))

const InfPrevencao = lazy(() => import('../views/procedimentos/InfPrevencao'))

const Aconselhamento = lazy(() =>
  import('../views/procedimentos/Aconselhamento')
)

const Noticias = lazy(() => import('../views/noticias/Noticias'))

const UserProfile = lazy(() => import('../views/userprofile/UserProfile'))

const Dashboard = lazy(() => import('../views/dashboard/Dashboard'))

const Administration = lazy(() => import('../views/administration'))

const PerguntasFrequentes = lazy(() => import('../views/perguntasfrequentes'))
const ExportaGraficos = lazy(() => import('../views/exportagraficos'))

const PoliticaPrivacidade = lazy(() => import('../views/politicaprivacidade'))

var ThemeRoutes = [
  {
    index: true,
    path: '/',
    name: 'Início',
    icon: 'mdi mdi-home',
    component: Homepage,
  },
  {
    notVisible: true,
    private: true,
    admin: true,
    navlabel: false,
    path: '/administration',
    component: Administration,
  },
  {
    notVisible: true,
    path: '/noticias/view/:idNoticias',
    component: NoticiasItem,
  },
  {
    path: '/noticias',
    name: 'Notícias',
    keyModule: 'ma',
    icon: 'mdi mdi-newspaper',
    component: Noticias,
  },
  {
    path: '/avisos',
    name: 'Avisos/Alertas',
    icon: 'mdi mdi-alert-circle',
    keyModule: 'ma',
    component: Avisos,
  },
  {
    path: '/metereologia',
    name: 'Metereologia',
    state: 'Metereologia',
    icon: 'mdi mdi-cloud',
    keyModule: 'mmt',
    collapse: true,
    child: [
      {
        path: '/metereologia/previsao',
        name: 'Previsão',
        component: Meteo,
      },
      {
        path: '/metereologia/estacoes',
        name: 'Estações',
        component: Estacao,
      },
    ],
  },
  {
    path: '/ocorrencias',
    name: 'Ocorrências',
    keyModule: 'moc',

    icon: 'mdi mdi-map-marker',
    component: Mapa,
  },
  {
    path: '/procedimentos',
    name: 'Procedimentos',
    state: 'Procedimento',
    keyModule: 'ma',
    icon: 'mdi mdi-file',
    collapse: true,
    child: [
      {
        path: '/procedimentos/documentos',
        name: 'Orientações',
        component: Orientacoes,
      },
      {
        path: '/procedimentos/informacao',
        name: 'Autoproteçao',
        component: Procedimentos,
      },
    ],
  },
  {
    path: '/contactos',
    name: 'Contactos',
    icon: 'mdi mdi-phone',
    component: Contactos,
  },
  {
    path: '/perguntas-frequentes',
    name: 'Perguntas Frequentes',
    icon: 'mdi mdi-help',
    component: PerguntasFrequentes,
  },
  {
    path: '/politicaprivacidade',
    name: 'Política de Privacidade',
    icon: 'mdi mdi-help',
    navlabel: false,
    notVisible: true,
    component: PoliticaPrivacidade,
  },
  {
    path: '/dashboard/exporta',
    name: 'Exportar',
    navlabel: false,
    notVisible: true,
    private: true,
    component: ExportaGraficos,
  },
  {
    path: '/dashboard/(:type)?',
    name: 'A minha área',
    navlabel: false,
    notVisible: true,
    private: true,
    component: Dashboard,
  },
  {
    path: '/user',
    name: 'User',
    redirect: '/user/profile',
    navlabel: false,
    collapse: true,
    notVisible: true,
    private: true,
    child: [
      {
        path: '/user/profile',
        name: 'Perfil do Utilizador',
        private: true,
        component: UserProfile,
      },
    ],
  },
]
export default ThemeRoutes
