import {
  LOCATIONS_REQUESTING,
  LOCATIONS_SUCCESS,
  OCORRENCIAS_REQUESTING,
  OCORRENCIAS_SUCCESS,
  CONCELHOS_SUCCESS,
  DISTRITOS_SUCCESS,
  ENTIDADES_SUCCESS,
  RECURSOS_TIPOS_SUCCESS,
  RECURSOS_CLOSESTS_SUCCESS,
  PROCEDIMENTOS_SUCCESS,
  LOAD_SYNC_DOCUMENTOS_SUCCESS,
  RECURSOS_SUCCESS,
  MAPA_RESOURCES_SUCCESS,
  USERS_SUCCESS,
  RECURSOS_CATEGORIAS_SUCCESS,
  CLEAR_RESOURCE_MAPA,
  META_OCORRENCIAS_SUCCESS,
  CLEAR_PROCEDIMENTO,
  FREGUESIAS_SUCCESS,
  TIPOS_SUBTIPOS_ALERTAS,
  ENTIDADES_TIPOS,
} from './Action'

const initialState = {
  concelhoDefault: {},
  distritoDefault: {
    value: 1,
    label: 'Aveiro',
    ipma: 1010500,
  },
  locations: { requesting: false, successful: false, options: [] },
  users: { requesting: false, successful: false, options: [] },
  concelhos: { requesting: false, successful: false, options: [] },
  freguesias: { requesting: false, successful: false, options: [] },
  distritos: { requesting: false, successful: false, options: [] },
  ocorrencias: { requesting: false, successful: false, options: [] },
  procedimentos: { requesting: false, successful: false, options: [] },
  alertas: { requesting: false, successful: false, options: [] },
  entidades: { requesting: false, successful: false, options: [] },
  recursosTipos: { requesting: false, successful: false, options: [] },
  recursosCategorias: { requesting: false, successful: false, options: [] },
  recursosClosests: { requesting: false, successful: false, options: [] },
  metaocorrencias: { requesting: false, successful: false, options: [] },
  recursos: { requesting: false, successful: false, options: [] },
  documentos: { requesting: false, successful: false, options: [] },
  resources: { requesting: false, successful: false, options: [] },
  tiposSubtiposAlertas: { requesting: false, successful: false, options: [] },
  entidadesTipos: [],
}

const metaInformacao = function metaInformacaoReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case LOCATIONS_REQUESTING:
      return {
        ...state,
        locations: { requesting: true, successful: false, options: [] },
      }
    case LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: {
          requesting: false,
          successful: true,
          options: action.locations,
        },
      }
    case OCORRENCIAS_REQUESTING:
      return {
        ...state,
        ocorrencias: {
          requesting: true,
          successful: true,
          options: [],
        },
      }
    case OCORRENCIAS_SUCCESS:
      return {
        ...state,
        ocorrencias: {
          requesting: false,
          successful: true,
          options: action.ocorrencias.optionsList,
        },
      }
    case ENTIDADES_SUCCESS:
      return {
        ...state,
        entidades: {
          requesting: false,
          successful: true,
          options: action.entidades,
        },
      }
    case CONCELHOS_SUCCESS:
      return {
        ...state,
        concelhos: {
          requesting: false,
          successful: true,
          options: action.concelhos.optionsList,
        },
      }
    case FREGUESIAS_SUCCESS:
      return {
        ...state,
        freguesias: {
          requesting: false,
          successful: true,
          options: action.freguesias.optionsList,
        },
      }
    case TIPOS_SUBTIPOS_ALERTAS:
      return {
        ...state,
        tiposSubtiposAlertas: action.resources.optionsList,
      }
    case PROCEDIMENTOS_SUCCESS:
      return {
        ...state,
        procedimentos: {
          requesting: false,
          successful: true,
          options: action.procedimentos.optionsList,
        },
      }
    case LOAD_SYNC_DOCUMENTOS_SUCCESS:
      return {
        ...state,
        documentos: {
          requesting: false,
          successful: true,
          options: action.documentos.optionsList,
        },
      }
    case DISTRITOS_SUCCESS:
      return {
        ...state,
        distritos: {
          requesting: false,
          successful: true,
          options: action.distritos.optionsList,
        },
      }
    case RECURSOS_TIPOS_SUCCESS:
      return {
        ...state,
        recursosTipos: {
          requesting: false,
          successful: true,
          options: action.recursos,
        },
      }
    case RECURSOS_CATEGORIAS_SUCCESS:
      return {
        ...state,
        recursosCategorias: {
          requesting: false,
          successful: true,
          options: action.recursos,
        },
      }
    case RECURSOS_CLOSESTS_SUCCESS:
      return {
        ...state,
        recursosClosests: {
          requesting: false,
          successful: true,
          options: action.recursos,
        },
      }
    case RECURSOS_SUCCESS:
      return {
        ...state,
        recursos: {
          requesting: false,
          successful: true,
          options: action.recursos,
        },
      }
    case META_OCORRENCIAS_SUCCESS:
      return {
        ...state,
        metaocorrencias: {
          requesting: false,
          successful: true,
          options: action.metaocorrencias.result,
        },
      }
    case MAPA_RESOURCES_SUCCESS:
      return {
        ...state,
        resources: {
          requesting: false,
          successful: true,
          options: action.resources,
        },
      }
    case USERS_SUCCESS:
      return {
        ...state,
        users: {
          requesting: false,
          successful: true,
          options: action.users,
        },
      }
    case CLEAR_RESOURCE_MAPA:
      return {
        ...state,
        resources: {
          requesting: false,
          successful: true,
          options: [],
        },
      }
    case CLEAR_PROCEDIMENTO:
      return {
        ...state,
        resources: {
          requesting: false,
          successful: true,
          options: [],
        },
      }
    case ENTIDADES_TIPOS:
      return {
        ...state,
        entidadesTipos: action.entidadesTipos,
      }
    // Append the error returned from our api
    // set the success and requesting flags to false
    default:
      return state
  }
}

export default metaInformacao
