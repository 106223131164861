import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Nav,
  NavItem,
  NavLink,
  Button,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledCarousel,
  Progress,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
} from 'reactstrap'
import * as data from './Data'
import { Link } from 'react-router-dom'

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logoIcon from '../../../assets/images/logo-icon.png'
import logoText from '../../../assets/images/logo-icon-text.png'
import profilephoto from '../../../assets/images/users/5.png'
import { logoutUser } from '../../../redux/users/Action'
import { History } from '../../../jwt/_helpers'
import SelectCombo from './SelectCombo'

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [isSearch, setSearch] = useState(false)

  const dispatch = useDispatch()

  const settings = useSelector((state) => state.settings)
  const { isAuthenticated, user } = useSelector((state) => ({
    isAuthenticated: state.users.isAuthenticated,
    user: state.users.auth,
  }))

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const searchtoggle = () => {
    setCollapse(!collapse)
  }

  const showMobilemenu = () => {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar')
  }

  const sidebarHandler = () => {
    let element = document.getElementById('main-wrapper')
    switch (settings.activeSidebarType) {
      case 'full':
      case 'iconbar':
        element.classList.toggle('mini-sidebar')
        if (element.classList.contains('mini-sidebar')) {
          element.setAttribute('data-sidebartype', 'mini-sidebar')
        } else {
          element.setAttribute('data-sidebartype', settings.activeSidebarType)
        }
        break

      case 'overlay':
      case 'mini-sidebar':
        element.classList.toggle('full')
        if (element.classList.contains('full')) {
          element.setAttribute('data-sidebartype', 'full')
        } else {
          element.setAttribute('data-sidebartype', settings.activeSidebarType)
        }
        break
      default:
    }
  }

  return (
    <header className="topbar navbarbg" data-navbarbg={settings.activeNavbarBg}>
      <Navbar
        className={
          'top-navbar ' +
          (settings.activeNavbarBg === 'skin6' ? 'navbar-light' : 'navbar-dark')
        }
        expand="md"
      >
        <div
          className="navbar-header"
          id="logobg"
          data-logobg={settings.activeLogoBg}
        >
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className="nav-toggler d-block d-md-none"
            onClick={() => showMobilemenu()}
          >
            <i className="ti-menu ti-close" />
          </span>
          {/*--------------------------------------------------------------------------------*/}
          {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
          {/*--------------------------------------------------------------------------------*/}
          <NavbarBrand href="/">
            <b className="logo-icon">
              <img
                src={logoIcon}
                alt="homepage"
                className="dark-logo"
                width="48px"
              />
              <img
                src={logoIcon}
                alt="homepage"
                className="light-logo"
                width="48px"
              />
            </b>
            <span className="logo-text">
              <img
                src={logoText}
                alt="homepage"
                className="dark-logo"
                width="150px"
              />
              <img
                src={logoText}
                className="light-logo"
                alt="homepage"
                width="150px"
              />
            </span>
          </NavbarBrand>
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className="topbartoggler d-block d-md-none"
            onClick={toggle.bind(null)}
          >
            <i className="ti-more" />
          </span>
        </div>
        <Collapse
          className="navbarbg"
          isOpen={isOpen}
          navbar
          data-navbarbg={settings.activeNavbarBg}
        >
          <Nav className="float-left" navbar>
            <NavItem>
              <NavLink
                href="#"
                className="d-none d-md-block"
                onClick={() => sidebarHandler()}
              >
                <i className="ti-menu" />
              </NavLink>
            </NavItem>
            {isSearch && (
              <NavItem
                className="flex items-center"
                style={{ minWidth: '80vw', background: 'white !important' }}
              >
                <SelectCombo />
              </NavItem>
            )}
          </Nav>
          <Nav className="ml-auto float-right" navbar>
            {isAuthenticated && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret onClick={() => setSearch(!isSearch)}>
                  <i className="font-18 ti ti-search" />
                </DropdownToggle>
              </UncontrolledDropdown>
            )}
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Notifications Dropdown                                                   */}
            {/*--------------------------------------------------------------------------------*/}

            {/*--------------------------------------------------------------------------------*/}
            {/* End Notifications Dropdown                                                     */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Messages Dropdown                                                        */}
            {/*--------------------------------------------------------------------------------*/}
            {isAuthenticated && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <i className="font-18 mdi mdi-email" />
                </DropdownToggle>
                <DropdownMenu right className="mailbox">
                  <span
                    className="nav-link text-center link text-dark"
                    onClick={() => History.push('dashboard?tab=notificacoes')}
                  >
                    <b>Ver todas as notificações</b>{' '}
                    <i className="fa fa-angle-right" />
                  </span>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {/*<UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                <i className="flag-icon flag-icon-pt"></i>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>
                  <i className="flag-icon flag-icon-es"></i> Espanhol
                </DropdownItem>
                <DropdownItem>
                  <i className="flag-icon flag-icon-us"></i> Inglês
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* End Create New Dropdown                                                        */}
            {/*--------------------------------------------------------------------------------*/}
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Profile Dropdown                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="pro-pic">
                <img
                  src={profilephoto}
                  alt="user"
                  className="rounded-circle"
                  width="31"
                />
              </DropdownToggle>
              <DropdownMenu right className="user-dd" style={{ zIndex: 9999 }}>
                {isAuthenticated && (
                  <>
                    <div className="d-flex no-block align-items-center p-3 bg-blue-cira text-white mb-2">
                      <div className="">
                        <img
                          src={profilephoto}
                          alt="user"
                          className="rounded-circle"
                          width="60"
                        />
                      </div>
                      <div className="ml-2">
                        <h4 className="mb-0 text-white">{user.name}</h4>
                        <p className=" mb-0">{user.email}</p>
                      </div>
                    </div>
                    <DropdownItem>
                      <Link to="/user/profile" className="nav-link-cira">
                        <i className="ti-user mr-1 ml-1" /> A minha Conta
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link to="/user/profile" className="nav-link-cira">
                        <i className="mdi mdi-view-dashboard mr-1 ml-1" /> As
                        minhas Entidades
                      </Link>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                      onClick={() => dispatch(logoutUser())}
                      className="text-gray-cira"
                    >
                      <i className="mdi mdi-logout mr-1 ml-1" /> Logout
                    </DropdownItem>
                  </>
                )}
                {!isAuthenticated && (
                  <>
                    <DropdownItem>
                      <Link
                        to="/authentication/login"
                        className="nav-link-cira"
                      >
                        <i className="mdi mdi-login mr-1 ml-1" /> Login
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link
                        to="/authentication/register"
                        className="nav-link-cira"
                      >
                        <i className="mdi mdi-account-plus mr-1 ml-1" />{' '}
                        Registar Utilizador
                      </Link>
                    </DropdownItem>
                    <DropdownItem>
                      <Link
                        to="/authentication/recover"
                        className="nav-link-cira"
                      >
                        <i className="mdi mdi-lock mr-1 ml-1" /> Recuperar
                        palavra-passe
                      </Link>
                    </DropdownItem>
                  </>
                )}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  )
}

export default Header
