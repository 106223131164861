import { Api } from '../../jwt/_services/OldApi'

export const LOCATIONS_REQUESTING = 'LOCATIONS_REQUESTING'
export const OCORRENCIAS_REQUESTING = 'OCORRENCIAS_REQUESTING'
export const LOCATIONS_SUCCESS = 'LOCATIONS_SUCCESS'
export const OCORRENCIAS_SUCCESS = 'OCORRENCIAS_SUCCESS'
export const CONCELHOS_SUCCESS = 'CONCELHOS_SUCCESS'
export const DISTRITOS_SUCCESS = 'DISTRITOS_SUCCESS'
export const FREGUESIAS_SUCCESS = 'FREGUESIAS_SUCCESS'
export const PROCEDIMENTOS_SUCCESS = 'PROCEDIMENTOS_SUCCESS'
export const ENTIDADES_SUCCESS = 'ENTIDADES_SUCCESS'
export const USERS_SUCCESS = 'USERS_SUCCESS'
export const LOAD_ASYNC_OPTION = 'LOAD_ASYNC_OPTION'
export const RECURSOS_TIPOS_SUCCESS = 'RECURSOS_TIPOS_SUCCESS'
export const RECURSOS_CATEGORIAS_SUCCESS = 'RECURSOS_CATEGORIAS_SUCCESS'
export const RECURSOS_CLOSESTS_SUCCESS = 'RECURSOS_CLOSESTS_SUCCESS'
export const LOAD_SYNC_DOCUMENTOS_SUCCESS = 'LOAD_SYNC_DOCUMENTOS_SUCCESS'
export const RECURSOS_SUCCESS = 'RECURSOS_SUCCESS'
export const MAPA_RESOURCES_SUCCESS = 'MAPA_RESOURCES_SUCCESS'
export const CLEAR_RESOURCE_MAPA = 'CLEAR_RESOURCE_MAPA'
export const CLEAR_PROCEDIMENTO = 'CLEAR_PROCEDIMENTO'
export const META_OCORRENCIAS_SUCCESS = 'META_OCORRENCIAS_SUCCESS'
export const TIPOS_SUBTIPOS_ALERTAS = 'TIPOS_SUBTIPOS_ALERTAS'
export const ENTIDADES_TIPOS = 'ENTIDADES_TIPOS'

export const locationsRequest = () => ({
  type: LOCATIONS_REQUESTING,
})

export const entidadesTipos = (entidadesTipos) => ({
  type: ENTIDADES_TIPOS,
  entidadesTipos,
})

export const resourceClear = () => ({
  type: CLEAR_RESOURCE_MAPA,
})

export const procedimentoClear = () => ({
  type: CLEAR_PROCEDIMENTO,
})

export const locationsSave = (locations) => ({
  type: LOCATIONS_SUCCESS,
  locations,
})

export const loadAsyncOptions = (searchTerm) => ({
  type: LOAD_ASYNC_OPTION,
  searchTerm,
})

export const ocorrenciasRequest = () => ({
  type: OCORRENCIAS_REQUESTING,
})

export const ocorrenciasSave = (ocorrencias) => ({
  type: OCORRENCIAS_SUCCESS,
  ocorrencias,
})

export const concelhosSave = (concelhos) => ({
  type: CONCELHOS_SUCCESS,
  concelhos,
})

export const freguesiasSave = (freguesias) => ({
  type: FREGUESIAS_SUCCESS,
  freguesias,
})

export const distritosSave = (distritos) => ({
  type: DISTRITOS_SUCCESS,
  distritos,
})

export const entidadesSave = (entidades) => ({
  type: ENTIDADES_SUCCESS,
  entidades,
})

export const usersSave = (users) => ({
  type: USERS_SUCCESS,
  users,
})

export const procedimentosSave = (procedimentos) => ({
  type: PROCEDIMENTOS_SUCCESS,
  procedimentos,
})

export const recursosTiposSave = (recursos) => ({
  type: RECURSOS_TIPOS_SUCCESS,
  recursos,
})

export const recursosCategoriasSave = (recursos) => ({
  type: RECURSOS_CATEGORIAS_SUCCESS,
  recursos,
})

export const recursosClosestsSave = (recursos) => ({
  type: RECURSOS_CLOSESTS_SUCCESS,
  recursos,
})

export const recursosSave = (recursos) => ({
  type: RECURSOS_SUCCESS,
  recursos,
})

export const metaOcorrenciasSave = (metaocorrencias) => ({
  type: META_OCORRENCIAS_SUCCESS,
  metaocorrencias,
})

export const documentosSave = (documentos) => ({
  type: LOAD_SYNC_DOCUMENTOS_SUCCESS,
  documentos,
})

export const resourcesSave = (resources) => ({
  type: MAPA_RESOURCES_SUCCESS,
  resources,
})

export const tiposSubTiposAlertas = (resources) => ({
  type: TIPOS_SUBTIPOS_ALERTAS,
  resources,
})

export function getInitMeta() {
  return async function (dispatch, getState) {
    const { meta } = getState()

    if (meta.distritos.options.length > 0) return

    const metaAvisos = await Api.fetchAsyncTiposSubTiposAlertas()
    const concelhosList = await Api.fetchAsyncConcelhosOptions({
      searchTerm: '',
      iddistrito: meta.distritoDefault.value,
    })
    const distritosList = await Api.fetchAsyncDistritosOptions({
      searchTerm: '',
    })

    dispatch(tiposSubTiposAlertas(metaAvisos))
    dispatch(concelhosSave(concelhosList))
    dispatch(distritosSave(distritosList))
  }
}

export function getUsers(path, activeEntidade, value) {
  return async function (dispatch, getState) {
    const { meta } = getState()

    const { users = [] } = await Api.fetchAsyncUsersOptions(
      path,
      activeEntidade,
      {
        searchTerm: value,
      }
    )
    dispatch(usersSave(users))
  }
}
