import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { getAPPModules } from './appmodules/Action'
import { getInitMeta } from './meta/Action'
import reducers from './Reducers'
import { loginUser } from './users/Action'

export function configureStore(initialState) {
  const store = createStore(reducers, initialState, applyMiddleware(thunk))
  store.dispatch(getInitMeta())
  store.dispatch(getAPPModules())
  const token = localStorage.getItem('CIRA_TOKEN')
  if (token) store.dispatch(loginUser(token))
  return store
}
