import DynamicApi from '../../jwt/_helpers/DynamicApi'

export const LOAD_MODULES = 'LOAD_MODULES'
export const CLEAN_MODULES = 'CLEAN_MODULES'
export const FETCH_MODULES = 'FETCH_MODULES'

export const addModules = (modules) => {
  return {
    type: FETCH_MODULES,
    modules,
  }
}

export function getAPPModules() {
  return async function (dispatch, getState) {
    const { modules } = await DynamicApi('/api/users/modules', 'GET')

    dispatch(addModules(modules))
  }
}
