import React, { useEffect } from 'react'
import indexRoutes from './routes/'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import { configureStore } from './redux/Store'
import { History } from './jwt/_helpers'
import img2 from './assets/images/browser/bg-logo.jpg'

import { PrivateRoute } from './routes/PrivateRoutes'
import MessageAlert from './components/alerts/MessageAlert'
import FullLayout from './layouts/FullLayout'
import { Button, Col, Row } from 'reactstrap'
import DynamicApi from './jwt/_helpers/DynamicApi'
const sidebarBackground = {
  backgroundImage: 'url(' + img2 + ')',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
}
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div
      className="auth-wrapper  align-items-center d-flex"
      style={sidebarBackground}
      role="alert"
    >
      <div className="container">
        <div>
          <Row className="no-gutters justify-content-center">
            <Col md="6" lg="4" className="bg-green-cira text-blue-cira">
              <div className="p-4">
                <h6 className="display-6">
                  Houve um problema com a aplicação!
                  <br />
                </h6>
                <p className="op-5 mt-4">{error.message}</p>
              </div>
            </Col>
            <Col
              md="6"
              lg="4"
              className="bg-white flex items-center justify-center"
            >
              <Button
                className="bg-blue-cira text-white"
                onClick={resetErrorBoundary}
              >
                Voltar a carregar a página
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

const App = () => {
  //const [currentUser, SetcurrentUser] = useState(null)

  useEffect(() => {
    DynamicApi('/api/users', 'GET')
  }, [])

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload()
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Provider store={configureStore()}>
        <Router history={History}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} key={key} component={prop.component} />
              )
            })}
          </Switch>
          <MessageAlert />
        </Router>
      </Provider>
    </ErrorBoundary>
  )
}
export default App
