import authorizationHeader from '../_helpers/AuthorizationHeader'
export const Api = {
  fetchOpenWeather: (city) =>
    fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=${city},pt&lang=pt&appid=503b5ff713e15617e142be7edd685a3b&units=metric`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      }),

  fetchIpmaWeather: (city) =>
    fetch(
      `https://api.ipma.pt/open-data/forecast/meteorology/cities/daily/${city}.json`,
      {
        method: 'GET',
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {}),

  subscribe: (payload, setErrors) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/users/subscribe`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Origin: '*',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        setErrors({ global: error.message })
      }),

  login: (email, password) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/users/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({
        credentials: { email: email, password: password },
      }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch(
        (error) => {
          throw error
        }
        //setErrors({ email: "O email já se encontra associado." })
      ),
  deleteUserGET: async () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/users/deletemyself`, {
      method: 'GET',
      withCredentials: true,
      headers: authorizationHeader(),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  storeToken: (token) => localStorage.setItem('CIRA_TOKEN', token),
  clearToken: () => localStorage.removeItem('CIRA_TOKEN'),
  signup: (values, setErrors) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/users/signup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ user: values }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  confirm: (token) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/users/confirmation`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ token: token }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  activateUser: (email, setErrors, setStatus) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/users/activate_user_request`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ email: email }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        setStatus('Foi enviado um email de ativação para a sua conta')
        return data
      })
      .catch((err) => {
        setErrors({ global: err.message })
      }),
  metaOcorrencias: () =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/metaOcorrencias`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  locations: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/mapa/locations`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  concelhoEntidadesPOST: (idconcelho) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/mapa/entidades/concelho`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ idconcelho }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        data.push({ value: 0, label: 'Todas' })
        return data
      })
      .catch((err) => {
        throw err
      }),
  concelhoEntidades: (idConcelho) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/mapa/concelhos/${idConcelho}/entidades`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),

  entidadeContactos: (idEntidade) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/ma/contactos/${idEntidade}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  entidadeContactosPrivados: (identidade, request_entidade) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/ma/contactos`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade, requestID: request_entidade }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchUserJWT: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/users/current_user`, {
      method: 'GET',
      withCredentials: true,
      headers: authorizationHeader(),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchUserMessages: () =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/users/current_user_messages`,
      {
        method: 'GET',
        withCredentials: true,
        headers: authorizationHeader(),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchUserProfile: () =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/users/current_user/profile`,
      {
        method: 'GET',
        withCredentials: true,
        headers: authorizationHeader(),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  saveUserProfile: (userProfile) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/users/current_user/profile`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ userProfile }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  saveUserSettings: (userProfile) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/users/current_user/settings`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ ...userProfile }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  situacaoAlertas: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/mapa/situacaoAlertas`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchPopupAviso: (id) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/ma/avisos/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchRecentAvisos: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/ma/avisos/recentes`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchProtectedRecentAvisos: (identidade) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ma/avisos/recentes?identidade=${identidade}`,
      {
        method: 'GET',
        withCredentials: true,
        headers: authorizationHeader(),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAvisoProtectedBody: (id, identidade) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ma/avisos/recentes/${id}?identidade=${identidade}`,
      {
        method: 'GET',
        withCredentials: true,
        headers: authorizationHeader(),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAvisoPublicBody: (id) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/ma/avisos/recentes/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAvisoDistritos: (idDistrito) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/ma/avisos/distrito/${idDistrito}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAlertasAvisosDistritos: (idDistrito, identidade) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/ma/avisos/distrito`, {
      method: 'POST',
      headers: authorizationHeader(),
      body: JSON.stringify({ iddistrito: idDistrito, identidade }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  viewNotification: (notification, idEntidade, apicall) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${apicall}/notifications`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ notification, identidade: idEntidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchGestorDashBoard: (idEntidade, apicall) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/${apicall}/overview`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade: idEntidade }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchOcorrenciaV2: (path, identidade, action, payload) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${path}/ocorrencias_v2`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ action, identidade, payload }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchOcorrenciasEntidade: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncOcorrenciasOptions: (searchTerm) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/options_list`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify(searchTerm),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncConcelhosOptions: (searchTerm, iddistrito) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/mapa/concelhos/options_list`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ searchTerm: searchTerm, iddistrito: iddistrito }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncConcelhosCIM: (searchTerm, iddistrito) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/mapa/cimdouro/municipios`, {
      method: 'GET',
      withCredentials: true,
      headers: authorizationHeader(),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncFreguesiasOptions: (searchTerm) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/mapa/freguesias/options_list`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify(searchTerm),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        data.optionsList.push({ value: 0, label: 'Todas' })
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncDistritosOptions: (searchTerm) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/mapa/distritos/options_list`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(searchTerm),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchPublicProcedimentoByKey: (key) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/ma/procedimentos/${key}/options_list`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchPrivateProcedimentoByKey: (identidade, key) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ma/procedimentos/${key}/options_list?identidade=${identidade}`,
      {
        method: 'GET',
        withCredentials: true,
        headers: authorizationHeader(),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncPublicProcedimentosOptions: (searchTerm) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/ma/procedimentos/options_list`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(searchTerm),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncProcedimentosOptions: (identidade, searchTerm) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ma/procedimentos/options_list`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade, ...searchTerm }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncEntidadesOptions: (type, identidade, searchTerm) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${type}/search/entidades`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade, ...searchTerm }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchEntidadesUsers: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/users/entidades`, {
      method: 'GET',
      withCredentials: true,
      headers: authorizationHeader(),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchEntidadeListaOptions: () =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/base/entidades/options`,
      {
        method: 'GET',
        withCredentials: true,
        headers: authorizationHeader(),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncUsersOptions: (type, identidade, searchTerm) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${type}/search/users`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade, ...searchTerm }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchSyncRecursosTiposOptions: () =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/recursos/subcategorias`,
      {
        method: 'GET',
        withCredentials: true,
        headers: authorizationHeader(),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncTiposSubTiposAlertas: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/resources/meta_avisos`, {
      method: 'GET',
      withCredentials: true,
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchSyncMetaOcorrencias: (identidade, action, payload) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/admin/meta_ocorrencias`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade, action, payload }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchSyncMetaOcorrenciasOptions: (table) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/admin/meta_ocorrencias_options`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ ...table }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchSyncMetaRecursos: (identidade, action, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/admin/meta_recursos`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade, action, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchSyncMetaRecursosOptions: (identidade, table) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/admin/meta_recursos_options`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade, table }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchSyncRecursosCategorias: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/recursos/categorias`, {
      method: 'GET',
      withCredentials: true,
      headers: authorizationHeader(),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchOcorrenciasOptions: (identidade, type) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${type}/ocorrencias_options`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  postAddUserEntidade: (identidade) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/users/concorrer`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify(identidade),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchGestaoDB: (
    identidade,
    subpath,
    path,
    action = 'get',
    payload = null,
    isMobile = false
  ) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/${subpath}/${path}`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade, action, payload, isMobile }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAdminGestaoDB: (identidade, path, action, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/admin/${path}`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ action, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchtiposRecursoGestaoDB: (path, payload, action) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/recursos/${path}`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ action, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  validateUsers: (id, decision, identidade, type) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${type}/validar/users`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ decision, id, identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  teste: () =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/meios_ativos/559`,
      {
        method: 'GET',
        withCredentials: true,
        headers: authorizationHeader(),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  postOcorrenciasDB: (action, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ action, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncClosestsRecursosOptionsNoCoords: (searchTerm, position) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/closest_meios_nocoords`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ ...searchTerm, ...position }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncClosestsRecursosOptions: (searchTerm, position) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/closest_meios`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ ...searchTerm, ...position }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncRecursosOptions: (searchTerm) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/recursos/search`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ ...searchTerm }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncClosestsRecursosById: (searchTerm, position) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/recursos/find`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ ...searchTerm, ...position }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchOcorrenciasRecursos: (action, payload) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/recursos`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ action, payload }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchListaOcorrenciasRelatorios: (idOcorrencia) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/lista_relatorios`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ idOcorrencia }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchOcorrenciasRelatorios: (idrel, idOcorrencia) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrencias/relatorios_v2`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ idrel, idOcorrencia }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then(async (response) => {
        if (response.headers.get('Content-Type').includes('application/pdf')) {
          const imageBlob = await response.blob()
          const imageURL = URL.createObjectURL(imageBlob)
          let a = document.createElement('a')
          a.href = imageURL
          a.target = '_blank'
          //a.download = response.headers.get('filename')
          document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click()
          return imageURL
        }
        return response
      })
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchDocumentosInternos: (path, identidade) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${path}/docs_internos`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  gestaoAvisos: (action, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/mapa/alertas`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ action, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  alterPassword: (payload) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/users/alter_password`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ payload }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  recoverPassword: (payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/users/reset_password_request`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  requestDeleteSubscription: (payload) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/users/delete_subscription_request`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ payload }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  deleteSubscription: (token) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/users/delete_subscription`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ token }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  changeRecoverPassword: (payload, token) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/users/reset_password`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify({ payload, token }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  gestaoAdminProcedimentos: (identidade, action, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/ma/procedimentos`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade, action, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  gestaoPublicProcedimentos: (action, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/ma/procedimentos`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ action, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  gestaoAdminGMLs: (identidade, path) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/${path}/lista_gmls`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  gestaoEditAdminGMLs: (path, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/${path}/gmls`, {
      method: 'POST',
      withCredentials: true,
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('CIRA_TOKEN')}`,
        Accept: 'application/json',
        //"Content-Type": 'multipart/form-data;charset=utf-8;boundary="???"',
        //"Content-Type": "application/x-www-form-urlencoded",
        'Access-Control-Allow-Origin': '*',
        credentials: 'include',
      }),
      body: payload,
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  uploadProcedimento: (action, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/ma/procedimentos`, {
      method: 'POST',
      withCredentials: true,
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('CIRA_TOKEN')}`,
        Accept: 'application/json',
        //"Content-Type": 'multipart/form-data;charset=utf-8;boundary="???"',
        //"Content-Type": "application/x-www-form-urlencoded",
        'Access-Control-Allow-Origin': '*',
        credentials: 'include',
      }),
      body: payload,
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  gestaoUserEntidade: (action, payload) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/users/entidades`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ action, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchOcorrenciasMapa: (route) =>
    fetch(`${process.env.REACT_APP_APIURL}/api${route}/mapa/ocorrencias`, {
      method: 'GET',
      headers:
        route !== ''
          ? authorizationHeader()
          : {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
            },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchConcelhosMapa: () =>
    fetch(
      `${process.env.REACT_APP_GEOURL}/geoserver/wfs?request=getfeature&typeName=sag:concelhos&outputFormat=application/json`,
      {
        method: 'GET',
        headers: {
          Accept:
            'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8',
        },
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchANPCOcorrenciasMapa: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/mapa/anpc_ocorrencias`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchEventosRecentes: () =>
    fetch(`${process.env.REACT_APP_APIURL}/api/mapa/eventos/recentes`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  prepareDownloadAssetsMapas: (identidade, type, id) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/admin/download_token`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return `${process.env.REACT_APP_APIURL}/api/protected/assets/mapas?type=${type}&id=${id}&token=${data.token}`
      })
      .catch((err) => {
        throw err
      }),
  fetchAsyncResourcesMapas: (type, identidade, searchTerm) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/${type}/mapa/search`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade, ...searchTerm }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchResourceInfoMapas: (identidade, path, type, id) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${path}/mapa/resources`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade, type, id }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchAdminCSVDB: (identidade, pretype, type, payload) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${pretype}/${type}/download_csv`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade, payload }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return `${process.env.REACT_APP_APIURL}/api/protected/assets/csv?fsName=${data.fsName}&token=${data.token}`
      })
      .catch((err) => {
        throw err
      }),
  importCSV: (body, pretype, type) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${pretype}/${type}/csv`,
      {
        method: 'POST',
        withCredentials: true,
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('CIRA_TOKEN')}`,
          Accept: 'application/json',
          //"Content-Type": 'multipart/form-data;charset=utf-8;boundary="???"',
          //"Content-Type": "application/x-www-form-urlencoded",
          'Access-Control-Allow-Origin': '*',
          credentials: 'include',
        }),
        body: body,
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),

  fetchImportVespas: (identidade) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrenciasV2/vespas/csv`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade, action: 'get' }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return `${process.env.REACT_APP_APIURL}/api/protected/assets/csv?fsName=${data.fsName}&token=${data.token}`
      })
      .catch((err) => {
        throw err
      }),
  fetchImportCSV: (identidade) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/ocorrenciasV2/csv`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade, action: 'get' }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return `${process.env.REACT_APP_APIURL}/api/protected/assets/csv?fsName=${data.fsName}&token=${data.token}`
      })
      .catch((err) => {
        throw err
      }),
  uploadImportCSV: (body) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/ocorrenciasV2/csv`, {
      method: 'POST',
      withCredentials: true,
      headers: new Headers({
        Authorization: `Bearer ${localStorage.getItem('CIRA_TOKEN')}`,
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        credentials: 'include',
      }),
      body: body,
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  exportPDFCSV: (identidade, type, payload, ext) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/pdfs/export`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade, payload }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchProcDocsInternos: (identidade, type, stepDocInt) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${type}/download_token`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return `${process.env.REACT_APP_APIURL}/api/protected/assets/docs?filename=${stepDocInt}&token=${data.token}`
      })
      .catch((err) => {
        throw err
      }),
  fetchProcDocsExternos: (identidade, type, path, procId) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${type}/download_token`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return `${process.env.REACT_APP_APIURL}/api/protected/assets/docs?procid=${procId}&filename=${path}&token=${data.token}`
      })
      .catch((err) => {
        throw err
      }),
  fetchProcOverview: (identidade, type, proc_path) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${type}/download_token`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return `${process.env.REACT_APP_APIURL}/api/protected/assets/procs_overview?filename=${proc_path}&token=${data.token}`
      })
      .catch((err) => {
        throw err
      }),
  fetchEstatisticas: (path, identidade, body) =>
    fetch(`${process.env.REACT_APP_APIURL}/api/protected/${path}/statistic`, {
      method: 'POST',
      withCredentials: true,
      headers: authorizationHeader(),
      body: JSON.stringify({ identidade, payload: body }),
    })
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchEstatisticasGeral: (path, identidade, tipo) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/${path}/statistics/geral/${tipo}`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchEstatisticasAtivas: (
    identidade,
    tipo,
    por,
    concelho,
    freguesia,
    entidade,
    tipo_oc,
    formato
  ) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/estatisticas/${tipo}/ativas/?por=${por}&concelho=${concelho}&freguesia=${freguesia}&entidade=${entidade}&tipo=${tipo_oc}&formato=${formato}`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchEstatisticasAlertasAtivas: (
    identidade,
    tipo,
    por,
    concelho,
    freguesia,
    entidade,
    tipo_oc,
    formato
  ) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/estatisticas/${tipo}/ativos/?por=${por}&concelho=${concelho}&tipo=${tipo_oc}&formato=${formato}`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchEstatisticasHistórico: (
    identidade,
    tipo,
    por,
    concelho,
    freguesia,
    entidade,
    tipo_oc,
    inicio,
    fim,
    formato
  ) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/estatisticas/${tipo}/historico/?por=${por}&concelho=${concelho}&freguesia=${freguesia}&tipo=${tipo_oc}&formato=${formato}&data_inicio=${inicio}&data_fim=${fim}`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchEstatisticasAlertasHistórico: (
    identidade,
    por,
    concelho,
    entidade,
    tipo_oc,
    inicio,
    fim,
    formato
  ) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/estatisticas/alertas/historico/?por=${por}&concelho=${concelho}&formato=${formato}&data_inicio=${inicio}&tipo=${tipo_oc}&data_fim=${fim}`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchEstatisticasMeiosAtivos: (
    identidade,
    por,
    concelho,
    freguesia,
    entidade,
    categoria,
    subcategoria,
    formato
  ) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/estatisticas/meios/ativos/?por=${por}&concelho=${concelho}&freguesia=${freguesia}&formato=${formato}&categoria=${categoria}&subcategoria=${subcategoria}&entidade=${entidade}`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchEstatisticasMeiosDisponiveis: (
    identidade,
    por,
    concelho,
    freguesia,
    entidade,
    categoria,
    subcategoria,
    formato
  ) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/estatisticas/meios/disponiveis/?por=${por}&concelho=${concelho}&freguesia=${freguesia}&formato=${formato}&categoria=${categoria}&subcategoria=${subcategoria}&entidade=${entidade}`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchEstatisticasMeiosHistorico: (
    identidade,
    por,
    concelho,
    freguesia,
    entidade,
    categoria,
    subcategoria,
    data_ini,
    data_fim,
    formato
  ) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/estatisticas/meios/historico/?por=${por}&concelho=${concelho}&freguesia=${freguesia}&formato=${formato}&categoria=${categoria}&subcategoria=${subcategoria}&entidade=${entidade}&data_inicio=${data_ini}&data_fim=${data_fim}`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ identidade }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
  fetchImagesOcorrencia: (identidade, idocorrencia) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrenciasV2/imagens`,
      {
        method: 'POST',
        withCredentials: true,
        headers: authorizationHeader(),
        body: JSON.stringify({ action: 'get', identidade, idocorrencia }),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return `${process.env.REACT_APP_APIURL}/api/protected/assets/csv?fsName=${data.fsName}&token=${data.token}`
      })
      .catch((error) => {
        throw error
      }),
  uploadImageOcorrencia: (body) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/protected/ocorrenciasV2/imagens`,
      {
        method: 'POST',
        withCredentials: true,
        headers: new Headers({
          Authorization: `Bearer ${localStorage.getItem('CIRA_TOKEN')}`,
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          credentials: 'include',
        }),
        body: body,
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((err) => {
        throw err
      }),
  fetchNoticia: (isProtected, identidade, action, body) =>
    fetch(
      `${process.env.REACT_APP_APIURL}/api/${
        isProtected ? 'protected/ma/' : 'ma/'
      }noticias`,
      {
        method: 'POST',
        withCredentials: true,
        headers:
          isProtected && action === 'add'
            ? new Headers({
                Authorization: `Bearer ${localStorage.getItem('CIRA_TOKEN')}`,
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
                credentials: 'include',
              })
            : authorizationHeader(),
        body:
          isProtected && action === 'add'
            ? body
            : JSON.stringify(
                isProtected
                  ? { action, payload: { id: body }, identidade }
                  : { action, payload: { id: body } }
              ),
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.statusText)
        return response
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.error !== undefined) throw Error(data.error)
        return data
      })
      .catch((error) => {
        throw error
      }),
}
