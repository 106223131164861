import {
  LOGIN_REQUESTING,
  LOGIN_SUCCESS,
  LOGIN_ADMIN,
  LOGIN_ERROR,
  LOGOUT,
  LOAD_USER_PAGE,
  OPEN_CMD_MODAL,
  CLOSE_CMD_MODAL,
  LOAD_USER_PAGE_SUCCESS,
  USER_SAVE_SETTINGS_SUCCESS,
  ADD_USER_ENTIDADE_SUCCESS,
  ADD_PUBLISHED_DOC,
  EDIT_USER_ENTIDADE_SUCCESS,
  ADD_ACTIVE_ENTIDADE_SUCCESS,
  RESPONSE_MESSAGE_SUCCESS,
  USER_SAVE_PROFILE_SUCCESS,
  SELECTED_PROCEDIMENTO_SUCCESS,
  DRAFT_ALERTA_SUCCESS,
  OPEN_NOTIFICATION_UPDATE,
  DRAFT_AVISO_SUCCESS,
  ADD_USER_MESSAGE_SUCCESS,
  CREATE_OCORRENCIA_SUCCESS,
  CREATE_OCORRENCIA_VALUES_SUCCESS,
  CREATE_SERVICOATIVIDADE_SUCCESS,
  CREATE_SERVICOATIVIDADE_VALUES_SUCCESS,
  ALERT_MESSAGE,
} from './Action'

const initialState = {
  requesting: false,
  admin: false,
  successful: false,
  isAuthenticated: false,
  message: null,
  messageAlert: {
    type: null,
    message: null,
  },

  errors: [],
  selectedProcedimento: null,
  draft_alerta: '',
  draft_aviso: '',
  user_profile: {},
  renewEntidades: [],
  renewRecursos: [],
  msgNotifications: [],
  lastPublishedDoc: null,
  notification: null,
  cmd: { open: false },
}

function getPath(idrole) {
  if (!idrole) return null
  const roles = {
    1: 'admin',
    2: 'municipios',
    3: 'entidades',
  }
  return roles[idrole]
}

function userProfileReducer(state, action) {
  switch (action.type) {
    case USER_SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        entidade_default: action.values.identidade_default,
        perfil: { ...state.perfil, ...action.values },
      }
    case ADD_USER_ENTIDADE_SUCCESS:
      return {
        ...state,
        entidades: [
          ...state.entidades,
          {
            id: action.values.id,
            tef_servico: '-',
            tel_servico: '-',
            email_servico: '-',
            fax_servico: '-',
            escalao: '-',
            identidade: action.values.idInstituicao.value,
            descr: action.values.idInstituicao.label,
            validation: true,
          },
        ],
      }
    case EDIT_USER_ENTIDADE_SUCCESS:
      if (action.key === 'delete') {
        let data = state.entidades
        data.splice(action.id, 1)
        return {
          ...state,
          entidades: data,
        }
      }

      if (action.key === 'edit') {
        let data = state.entidades
        data[action.id] = { ...action.values, descr: data[action.id].descr }
        return {
          ...state,
          entidades: data,
        }
      }
      return state
    default:
      return state
  }
}

const users = function userReducer(state = initialState, action) {
  switch (action.type) {
    case ALERT_MESSAGE:
      return {
        ...state,
        messageAlert: { type: action.alertType, message: action.message },
      }
    case CREATE_OCORRENCIA_SUCCESS:
      return {
        ...state,
        active_ocorrencia: action.idocorrencia,
        active_ocorrencia_values: null,
      }
    case CREATE_OCORRENCIA_VALUES_SUCCESS:
      return {
        ...state,
        active_ocorrencia_values: {
          ...action.values,
          ...action.values.location,
        },
        active_ocorrencia: null,
      }
    case CREATE_SERVICOATIVIDADE_SUCCESS:
      return {
        ...state,
        active_servicoatividade: action.idservicoatividade,
        active_servicoatividade_values: null,
      }
    case CREATE_SERVICOATIVIDADE_VALUES_SUCCESS:
      return {
        ...state,
        active_servicoatividade_values: {
          ...action.values,
          ...action.values.location,
        },
        active_servicoatividade: null,
      }
    case SELECTED_PROCEDIMENTO_SUCCESS:
      return {
        ...state,
        selectedProcedimento: action.values,
      }
    case RESPONSE_MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.values,
      }
    case LOGIN_REQUESTING:
      return {
        requesting: true,
        successful: false,
        errors: [],
        renewEntidades: [],
        renewRecursos: [],
        msgNotifications: [],
      }
    case ADD_USER_MESSAGE_SUCCESS:
      return {
        ...state,
        renewEntidades: action.messages.renewEntidades,
        renewRecursos: action.messages.renewRecursos,
        msgNotifications: action.messages.notificacoesDocumentos,
      }

    case LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        successful: true,
        isAuthenticated: true,
        auth: { ...action.users.auth },
        gestao: action.users.gestao,
        entidade_default: action.users.identidade_default,
        concelho_default: action.users.concelho_default,
        distrito_default: action.users.distrito_default,
        messageAlert: { type: 'success', message: 'Bem-vindo à plataforma!' },
      }
    case LOGOUT:
      return {
        errors: [],
        requesting: false,
        successful: true,
        admin: false,
        renewEntidades: [],
        renewRecursos: [],
        msgNotifications: [],
      }
    // Append the error returned from our api
    // set the success and requesting flags to false
    case LOGIN_ERROR:
      return {
        errors: state.errors.concat([
          {
            body: action.error.toString(),
            time: new Date(),
          },
        ]),
        messages: [],
        message: 'Erro durante a autenticação',
        requesting: false,
        successful: false,
        admin: false,
        renewEntidades: [],
        renewRecursos: [],
        msgNotifications: [],
      }
    case LOAD_USER_PAGE:
      return {
        ...state,
        errors: [],
        requesting: true,
        success: false,
      }
    case ADD_ACTIVE_ENTIDADE_SUCCESS:
      return {
        ...state,
        active_entidade: action.values,
        active_path: getPath(action.values ? action.values.idrole : null),
      }
    case LOAD_USER_PAGE_SUCCESS:
      return {
        ...state,
        requesting: false,
        success: true,
        user_profile: action.user_profile,
      }
    case LOGIN_ADMIN:
      return {
        ...state,
        admin: true,
      }
    case USER_SAVE_PROFILE_SUCCESS:
      return {
        ...state,
        entidade_default: action.values.identidade_default,
        message: 'Perfil atualizado com sucesso!',
      }
    case USER_SAVE_SETTINGS_SUCCESS:
      return {
        ...state,
        user_profile: userProfileReducer(state.user_profile, action),
        requesting: false,
        success: true,
        message: 'Perfil atualizado com sucesso!',
      }
    case ADD_USER_ENTIDADE_SUCCESS:
      return {
        ...state,
        user_profile: userProfileReducer(state.user_profile, action),
        requesting: false,
        success: true,
        message: 'Pedido de adesão efetuado com sucesso!',
      }
    case EDIT_USER_ENTIDADE_SUCCESS:
      return {
        ...state,
        user_profile: userProfileReducer(state.user_profile, action),
        requesting: false,
        success: true,
        message: 'Operação efetuada com sucesso!',
      }
    case DRAFT_ALERTA_SUCCESS:
      return {
        ...state,
        draft_alerta: action.text,
      }
    case DRAFT_AVISO_SUCCESS:
      return {
        ...state,
        draft_aviso: action.text,
      }
    case ADD_PUBLISHED_DOC:
      return {
        ...state,
        lastPublishedDoc: action.lastPublishedDoc,
      }
    case OPEN_NOTIFICATION_UPDATE:
      return { ...state, notification: action.notification }
    case OPEN_CMD_MODAL:
      return { ...state, cmd: { ...action.request, open: true } }
    case CLOSE_CMD_MODAL:
      return { ...state, cmd: { open: false } }
    default:
      return state
  }
}

export default users
