import authorizationHeader from './AuthorizationHeader'

// eslint-disable-next-line import/no-anonymous-default-export
export default (url, method, body, isAuth, isForm, isDownloadable = false) =>
  fetch(`${process.env.REACT_APP_APIURL}${url}`, {
    method,
    headers: isAuth
      ? isForm
        ? new Headers({
            Authorization: `Bearer ${localStorage.getItem('CIRA_TOKEN')}`,
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            credentials: 'include',
          })
        : authorizationHeader()
      : isForm
      ? new Headers({
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
        })
      : {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
    body:
      method === 'GET' || method === 'DELETE'
        ? null
        : isForm
        ? body
        : JSON.stringify(body),
  })
    .then((response) => {
      console.log(response)
      if (!response.ok) throw Error(response.statusText)
      return response
    })
    .then(async (response) => {
      if (isDownloadable) {
        const imageBlob = await response.blob()
        let fileName = 'download_file'
        const imageURL = URL.createObjectURL(imageBlob)
        let a = document.createElement('a')
        console.log(response)
        const contentDisposition = response.headers.get('Content-Disposition')

        if (contentDisposition && contentDisposition.includes('attachment')) {
          const fileNameMatch = contentDisposition.match(/filename="(.+)"/)
          if (fileNameMatch.length === 2) {
            fileName = fileNameMatch[1]
          }
        }
        a.href = imageURL
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)

        URL.revokeObjectURL(imageURL)
        return imageURL
      }
      if (
        response.headers.get('Content-Type').includes('python') ||
        response.headers.get('Content-Type') === 'application/pdf'
      ) {
        const imageBlob = await response.blob()
        const imageURL = URL.createObjectURL(imageBlob)
        return imageURL
      }
      if (
        response.headers.get('Content-Type').includes('image') ||
        response.headers.get('Content-Type') === 'application/pdf'
      ) {
        const imageBlob = await response.blob()
        const imageURL = URL.createObjectURL(imageBlob)
        return imageURL
      }
      if (response.headers.get('Content-Type').includes('application/xml')) {
        const imageBlob = await response.blob()
        const imageURL = URL.createObjectURL(imageBlob)
        let a = document.createElement('a')
        a.href = imageURL
        a.download = response.headers.get('filename')
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        return imageURL
      }
      if (response.headers.get('Content-Type').includes('application/zip')) {
        const imageBlob = await response.blob()
        const imageURL = URL.createObjectURL(imageBlob)
        let a = document.createElement('a')
        a.href = imageURL
        a.download = 'Buffer Export.zip'
        document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click()
        return imageURL
      }
      return response.json()
    })
    .then((data) => {
      if (data.error !== undefined) throw Error(data.error)
      return data
    })
    .catch((error) => {
      throw error
    })
