import { History } from '../../jwt/_helpers'
import { Api } from '../../jwt/_services/OldApi'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ADMIN = 'LOGIN_ADMIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGOUT = 'LOGOUT'
export const ADD_PUBLISHED_DOC = 'ADD_PUBLISHED_DOC'
export const LOGIN_REQUESTING_JWT = 'LOGIN_REQUESTING_JWT'
export const LOGIN_REQUESTING = 'LOGIN_REQUESTING'
export const LOAD_USER_PAGE = 'LOAD_USER_PAGE'
export const LOAD_USER_PAGE_SUCCESS = 'LOAD_USER_PAGE_SUCCESS'
export const USER_SAVE_PROFILE = 'USER_SAVE_PROFILE'
export const ADD_USER_ENTIDADE = 'ADD_USER_ENTIDADE'
export const ADD_USER_ENTIDADE_SUCCESS = 'ADD_USER_ENTIDADE_SUCCESS'
export const EDIT_USER_ENTIDADE = 'EDIT_USER_ENTIDADE'
export const EDIT_USER_ENTIDADE_SUCCESS = 'EDIT_USER_ENTIDADE_SUCCESS'
export const USER_ALTER_PASSWORD = 'USER_ALTER_PASSWORD'
export const USER_SAVE_PROFILE_SUCCESS = 'USER_SAVE_PROFILE_SUCCESS'
export const USER_SAVE_SETTINGS = 'USER_SAVE_SETTINGS'
export const USER_SAVE_SETTINGS_SUCCESS = 'USER_SAVE_SETTINGS_SUCCESS'
export const ADD_ACTIVE_ENTIDADE_SUCCESS = 'ADD_ACTIVE_ENTIDADE_SUCCESS'
export const RESPONSE_MESSAGE_SUCCESS = 'RESPONSE_MESSAGE_SUCCESS'
export const ALERT_MESSAGE = 'ALERT_MESSAGE'
export const SELECTED_PROCEDIMENTO_SUCCESS = 'SELECTED_PROCEDIMENTO_SUCCESS'
export const DRAFT_AVISO_SUCCESS = 'DRAFT_AVISO_SUCCESS'
export const DRAFT_ALERTA_SUCCESS = 'DRAFT_ALERTA_SUCCESS'
export const ADD_USER_MESSAGE_SUCCESS = 'ADD_USER_MESSAGE_SUCCESS'
export const CREATE_OCORRENCIA_SUCCESS = 'CREATE_OCORRENCIA_SUCCESS'
export const CREATE_OCORRENCIA_VALUES_SUCCESS =
  'CREATE_OCORRENCIA_VALUES_SUCCESS'
export const CREATE_SERVICOATIVIDADE_SUCCESS = 'CREATE_SERVICOATIVIDADE_SUCCESS'
export const CREATE_SERVICOATIVIDADE_VALUES_SUCCESS =
  'CREATE_SERVICOATIVIDADE_VALUES_SUCCESS'
export const OPEN_NOTIFICATION_UPDATE = 'OPEN_NOTIFICATION_UPDATE'
export const OPEN_CMD_MODAL = 'OPEN_CMD_MODAL'
export const CLOSE_CMD_MODAL = 'CLOSE_CMD_MODAL'

const getDefaultEntidade = (gestao, identidade) => {
  let gestaoOptions = gestao.map((entidade) => ({
    value: entidade.identidade,
    label: entidade.descr,
    idrole: entidade.idrole,
    escrita: entidade.escrita,
    users: entidade.users,
    idconcelho: entidade.idconcelho,
    iddistrito: entidade.iddistrito,
  }))
  let flag = 0
  for (let index in gestaoOptions) {
    if (gestaoOptions[index].value === identidade) flag = index
  }
  return gestaoOptions[flag]
}

export const userLoginRequest = (email, password, setErrors) => ({
  type: LOGIN_REQUESTING,
  email: email,
  password: password,
  setErrors: setErrors,
})

export const userAlterPassword = (email, password, setErrors) => ({
  type: LOGIN_REQUESTING,
  email: email,
  password: password,
  setErrors: setErrors,
})

export const userSaveProfile = (values) => ({
  type: USER_SAVE_PROFILE,
  values,
})

export const userAdminLogin = () => ({
  type: LOGIN_ADMIN,
})

export const userSaveProfileSuccess = (values) => ({
  type: USER_SAVE_PROFILE_SUCCESS,
  values,
})

export const userSaveSettingsSuccess = (values) => ({
  type: USER_SAVE_SETTINGS_SUCCESS,
  values,
})

export const userLoginRequestJWT = () => ({
  type: LOGIN_REQUESTING_JWT,
})

export const userLogin = (users) => ({
  type: LOGIN_SUCCESS,
  users,
})

export const userLoginError = (error) => ({
  type: LOGIN_ERROR,
  error,
})

export const messageAlert = (alertType, message) => ({
  type: ALERT_MESSAGE,
  alertType,
  message,
})

export const userLogout = () => ({
  type: LOGOUT,
})

export const userMessages = (messages) => ({
  type: ADD_USER_MESSAGE_SUCCESS,
  messages,
})

export const loadUserPage = () => ({
  type: LOAD_USER_PAGE,
})

export const loadUserPageSuccess = (profile) => ({
  type: LOAD_USER_PAGE_SUCCESS,
  user_profile: profile,
})

export const addUserEntidade = (entidade, setErrors) => ({
  type: ADD_USER_ENTIDADE,
  entidade,
  setErrors,
})

export const addOcorrenciaID = (idocorrencia) => ({
  type: CREATE_OCORRENCIA_SUCCESS,
  idocorrencia,
})

export const addOcorrenciaValues = (values) => ({
  type: CREATE_OCORRENCIA_VALUES_SUCCESS,
  values,
})

export const addServicoAtividadeID = (idservicoatividade) => ({
  type: CREATE_SERVICOATIVIDADE_SUCCESS,
  idservicoatividade,
})

export const addServicoAtividadeValues = (values) => ({
  type: CREATE_SERVICOATIVIDADE_VALUES_SUCCESS,
  values,
})

export const addPublishedDoc = (lastPublishedDoc) => ({
  type: ADD_PUBLISHED_DOC,
  lastPublishedDoc,
})

export const editUserEntidade = (
  operation,
  entidade,
  id,
  setErrors,
  closeDialog
) => ({
  type: EDIT_USER_ENTIDADE,
  operation,
  entidade,
  setErrors,
  id,
  closeDialog,
})

export function addActiveEntidade(data) {
  return async function (dispatch, getState) {
    const { users } = getState()
    dispatch({
      type: 'ADD_ACTIVE_ENTIDADE_SUCCESS',
      values: getDefaultEntidade(users.gestao, data.value),
    })
  }
}

export function loginUser(token, data) {
  return async function (dispatch, getState) {
    const { users } = getState()

    if (users.isAdmin) return History.push('/administration')
    if (users.isAuthenticated) return History.push('/user/profile')

    if (!data) {
      //IF Data null is autologin with JWT
      try {
        const userToken = await Api.fetchUserJWT()
        dispatch(userLogin(userToken))
        dispatch({
          type: 'ADD_ACTIVE_ENTIDADE_SUCCESS',
          values: getDefaultEntidade(
            userToken.gestao,
            userToken.identidade_default
          ),
        })
        localStorage.setItem('CIRA_TOKEN', userToken.auth.token)

        const messages = await Api.fetchUserMessages()
        dispatch(userMessages(messages))
      } catch (error) {
        dispatch(
          messageAlert(
            'warning',
            'Sessão expirou. Por favor, efetue novamente o login.'
          )
        )
        localStorage.removeItem('CIRA_TOKEN')
      } finally {
      }
      return
    }
    if (data.admin) {
      localStorage.setItem('CIRA_TOKEN', data.auth)
      dispatch(userAdminLogin())
      return History.push('/administration')
    }

    dispatch(userLogin(data))
    dispatch({
      type: 'ADD_ACTIVE_ENTIDADE_SUCCESS',
      values: getDefaultEntidade(data.gestao, data.identidade_default),
    })
    localStorage.setItem('CIRA_TOKEN', token)

    History.push('/user/profile')
    const messages = await Api.fetchUserMessages()
    dispatch(userMessages(messages))
  }
}

export function logoutUser() {
  return async function (dispatch, getState) {
    localStorage.removeItem('CIRA_TOKEN')
    dispatch(userLogout())
    dispatch(messageAlert('success', 'Sessão terminada com sucesso'))
    History.push('/')
  }
}
