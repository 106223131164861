import { CLEAN_MODULES, FETCH_MODULES } from './Action'

const initialState = {
  modules: {},
  modulesLoading: true,
}

const appModules = function appModulesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MODULES:
      let parsedModules = {}
      action?.modules?.forEach(
        (elem) => (parsedModules[elem.key] = elem.active)
      )
      return {
        modules: parsedModules,
        modulesLoading: false,
      }
    case CLEAN_MODULES:
      return {
        modules: {},
      }
    default:
      return state
  }
}

export default appModules
