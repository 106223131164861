import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { AuthenticationService } from '../jwt/_services'

export const AdminRoute = ({
  component: Component,
  isAuthenticated,
  isAdmin,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        // authorised so return component
        if (!isAuthenticated && !isAdmin) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: '/authentication/login',
                state: { from: props.location },
              }}
            />
          )
        }
        return <Component {...props} />
      }}
    />
  )
}

export default AdminRoute
