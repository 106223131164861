import React from 'react'

const Footer = () => {
  return (
    <footer className="footer flex-column text-center flex justify-center items-center">
      <div>
        <img
          style={{ width: '350px' }}
          src={`${process.env.REACT_APP_APIURL}/POSEUR_PT2020_FC_Bom.png`}
        />
      </div>

      <div
        className="m-2 mt-4 flex justify-center cursor-pointer"
        style={{ color: '#0079b2' }}
      >
        <a
          className="mx-2"
          href="https://play.google.com/store/apps/details?id=com.geoatributo.cira"
          target="_blank"
          title="Playstore"
        >
          <img
            src={`${process.env.REACT_APP_APIURL}/gplay.png`}
            style={{ width: '100px' }}
          />
        </a>
        <a
          className="mx-2"
          href="https://apps.apple.com/pt/app/cira/id1628528643?platform=iphone"
          target="_blank"
          title="iOS App Store"
        >
          <img
            src={`${process.env.REACT_APP_APIURL}/appstore.png`}
            style={{ width: '25px' }}
          />
        </a>
      </div>
      <div className="my-1">
        {' '}
        <a
          href="ficha_projeto_SEGURA.pdf"
          download={true}
          title="Ficha do Projeto"
          style={{ color: 'rgb(103, 117, 124)' }}
        >
          Ficha do Projeto
        </a>
      </div>
      <div>
        &copy; {1900 + new Date().getYear()}{' '}
        <a
          href="http://www.geoatributo.pt"
          target="_blank"
          style={{ color: 'rgb(103, 117, 124)' }}
        >
          GeoAtributo - Versão 1.3.0c
        </a>
      </div>
    </footer>
  )
}
export default Footer
