import { combineReducers } from 'redux'
import appModules from './appmodules/Reducer'
import settings from './settings/Reducer'
import users from './users/Reducer'
import meta from './meta/Reducer'

const reducers = combineReducers({
  appModules,
  settings,
  users,
  meta,
})

export default reducers
